import React from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink } from "react-router-dom";

function SliderBar() {
  return (
    <div style={{ height: "100%" }}>
      <CDBSidebar textColor="#fff" backgroundColor="#0d6efd">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a
            href="/"
            className="text-decoration-none"
            style={{ color: "inherit" }}
          >
            Sidebar
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink exact to="/Inicio/" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="home">Inicio</CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/Inicio/usuarios"
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem icon="user">Usuarios</CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/Inicio/maestros"
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem icon="chalkboard-teacher">
                Instructores
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/Inicio/categorias"
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem icon="list-alt">
                Categorias
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/Inicio/cursos" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="file-alt">Cursos</CDBSidebarMenuItem>
            </NavLink>

            <NavLink
              exact
              to="/Inicio/certificados"
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem icon="medal">Certificados</CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/Inicio/calendario"
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem icon="list-alt">
                Calendario
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/Inicio/solicitudes"
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem icon="list-alt">
                solicitudes
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink exact to="/" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="power-off">
                Cerrar sesión
              </CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: "center" }}>
          <div
            style={{
              padding: "20px 5px",
            }}
          >
            Universidad Bluehand
          </div>
        </CDBSidebarFooter>
        {/* <CDBSidebarFooter>
          <div>
            <div className="btn-group dropdown-primary">
              <button
                type="button"
                className="btn btn-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Primary
              </button>
              <div className="dropdown-menu">
                <a className="dropdown-item" href="#">
                  Home
                </a>
                <a className="dropdown-item" href="#">
                  Services
                </a>
                <a className="dropdown-item" href="#">
                  About us
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#">
                  Contact us
                </a>
              </div>
            </div>
          </div>
        </CDBSidebarFooter> */}
      </CDBSidebar>
    </div>
  );
}
export default SliderBar;
