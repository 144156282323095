import React from "react";
import { useNavigate } from "react-router-dom";

function NavBar() {
  let navigate = useNavigate();
  function toAuth() {
    console.log("toauth");
    navigate("/Ingresar");
  }
  return (
    <header id="topnav" className="defaultscroll sticky">
      <div className="container">
        <a className="logo">
          {/* <img
            src="assets/images/logo-dark.png"
            height="24"
            className="logo-light-mode"
            alt=""
          />
          <img
            src="assets/images/logo-light.png"
            height="24"
            className="logo-light-mode"
            alt=""
          /> */}
          <h2 style={{ color: "#0d6efd" }} className="logo-light-mode">
            BlueHand
          </h2>
        </a>
        <div className="buy-button">
          <button onClick={toAuth} className="btn btn-primary">
            Ingresar/Registrar
          </button>
        </div>
        <div className="menu-extras">
          <div className="menu-item">
            <button
              className="navbar-toggle"
              id="isToggle"
              onClick="toggleMenu()"
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
        </div>
        <div id="navigation">
          <ul className="navigation-menu">
            <li>
              <a className="sub-menu-item">Inicio</a>
            </li>
            <li>
              <a className="sub-menu-item">Cursos</a>
            </li>
            <li>
              <a className="sub-menu-item">Instructores</a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
export default NavBar;
