import React from "react";
import { Outlet } from "react-router-dom";
import SliderBar from "./components/SliderBar";

function HomeScreen() {
  return (
    <div
      style={{ display: "flex", height: "100%", overflow: "scroll initial" }}
    >
      <SliderBar />
      <div className="container">
        <Outlet />
      </div>
    </div>
  );
}
export default HomeScreen;
