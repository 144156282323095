import axios from "axios";
import React, { useEffect, useState } from "react";
import "./acordeon.css";
import "./modal.css";
import { API_URL } from "../Global";
import $ from "jquery";
import "datatables.net";
import "./../datatables.css";
import Swal from "sweetalert2";

function UserScreen() {
  const [isActive, setIsActive] = useState(false);
  const [users, setUsers] = useState([]);
  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");
  const [tipo, setTipo] = useState("");

  useEffect(() => {
    const getUsers = async () => {
      const response = await axios.post(`${API_URL}users/listaUsuarios`);
      console.log(response);
      if (response.data.result_code == 200) {
        const newUsers = response.data.data.map((user) => {
          let rol = "NA";
          switch (user.role_usuario) {
            case 1:
              rol = "Administrador";
              break;
            case 2:
              rol = "Gestor";
              break;
            case 3:
              rol = "Profesor";
              break;
            case 4:
              rol = "RH";
              break;
            case 5:
              rol = "Alumno";
              break;
            default:
              rol = "NA";
              break;
          }
          return {
            id_usuario: user.id_usuario,
            nombre_usuario: user.nombre_usuario,
            role: rol,
          };
        });
        setUsers(newUsers);
      }
    };

    const interval = setInterval(() => {
      getUsers();
    }, 5000);

    // Limpia el intervalo cuando el componente se desmonta
    return () => clearInterval(interval);
  }, [users]);

  // Llamamos a la función de DataTables solo una vez, cuando se monta el componente
  useEffect(() => {
    if (users.length > 0) {
      if (!$.fn.DataTable.isDataTable("#tabla_usuarios")) {
        $("#tabla_usuarios").DataTable({
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
          },
        });
      }
    }
  }, [users]);

  function EliminarUsuario(uid) {
    console.log(EliminarUsuario, " ", uid);
    // nombre_usuario
    Swal.fire({
      title: `Eliminar usuario : <span> ${uid}</span>`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Continuar",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire("Se elimino de manera correcta!", "", "success");
      } else if (result.isDenied) {
        Swal.fire("Se cancelo la eliminación", "", "info");
      }
    });
  }

  const EditarUsuario = async ({ id_usuario, nombre_usuario, role }) => {
    const roles = ["Alumno", "Gestor", "RH"];
    const status = ["Activo", "Desactivo"];
    const selectOptions = roles
      .map((option) => `<option value="${option}">${option}</option>`)
      .join("");
    const selectStatus = status
      .map((stat) => `<option value="${stat}">${stat}</option>`)
      .join("");

    const { value: formValues } = await Swal.fire({
      title: "Editar Usuario",
      html: `<form>
          <input id="nombre_usuario" className="swal2-input custom-select" placeholder="Nombre Usuario" value="${nombre_usuario}">
          <select id="role"  className="swal2-input custom-select" value="${role}">
            ${selectOptions}
          </select>
          <select id="role"  className="swal2-input custom-select" value="${status}">
            ${selectStatus}
          </select>
        </form>`,
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById("id_usuario").value,
          document.getElementById("nombre_usuario").value,
          document.getElementById("role").value,
        ];
      },
    });

    if (formValues) {
      Swal.fire(JSON.stringify(formValues));
    }
  };

  const handlerNombreChange = (event) => {
    setNombre(event.target.value);
  };
  const handlerCorreoChange = (event) => {
    setCorreo(event.target.value);
  };
  const handlerPasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handlerTipoChange = (event) => {
    setTipo(event.target.value);
  };
  const guardarUsuario = async (event) => {
    event.preventDefault();
    console.log("NOMBRE : ", nombre);
    console.log("CORREO : ", correo);
    console.log("PASSWORD : ", password);
    console.log("TIPO : ", tipo);
    if (nombre === "" || correo === "" || password === "" || tipo === 0) {
      console.log("vienen vacios");
      alert("llene todos los campos");
    } else {
      console.log("si estan llenos");
      const response = await axios.post(`${API_URL}users/registro`, {
        nombre: nombre,
        correo: correo,
        password: password,
        rol: tipo,
      });
      console.log(response);
      if (response.status === 200) {
        console.log(response.data.message);
        alert(response.data.message);
        if (response.data.result_code === 400) {
          console.log("ERROR", response.data.error);
        }
        if (response.data.result_code === 200) {
        }
      }
    }
  };
  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="col-12  mt-4 pt-2 ">
            <div className="component-wrapper rounded shadow">
              <div style={{ justifyContent: "center" }}>
                <div className="accordion">
                  <div className="accordion-item">
                    <div
                      className="accordion-title"
                      onClick={() => setIsActive(!isActive)}
                    >
                      <div>Agregar Usuario</div>
                      <div>{isActive ? "-" : "+"}</div>
                    </div>
                    {isActive && (
                      <div className="accordion-content">
                        <div>
                          {/* form */}
                          <form>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Nombre{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <i
                                      data-feather="user"
                                      className="fea icon-sm icons"
                                    ></i>
                                    <input
                                      name="nombre"
                                      id="nombre"
                                      type="text"
                                      value={nombre}
                                      onChange={handlerNombreChange}
                                      className="form-control ps-5"
                                      placeholder="Nombre :"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Correo{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <i
                                      data-feather="user"
                                      className="fea icon-sm icons"
                                    ></i>
                                    <input
                                      name="correo"
                                      id="correo"
                                      type="text"
                                      onChange={handlerCorreoChange}
                                      className="form-control ps-5"
                                      placeholder="Correo :"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Contraseña{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <i
                                      data-feather="user"
                                      className="fea icon-sm icons"
                                    ></i>
                                    <input
                                      name="password"
                                      id="password"
                                      type="password"
                                      onChange={handlerPasswordChange}
                                      className="form-control ps-5"
                                      placeholder="Contraseña :"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Tipo de usuario
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <i
                                      data-feather="user"
                                      className="fea icon-sm icons"
                                    ></i>
                                    <select
                                      name="tipo"
                                      id="tipo"
                                      className="form-control ps-5"
                                      onChange={handlerTipoChange}
                                    >
                                      {/* value={0}
                                      value={5}
                                      value={1}
                                      value={3}
                                      value={2} */}
                                      <option value={0}>Selecione...</option>
                                      <option value={5}>Alumno</option>
                                      <option value={1}>Administrador</option>
                                      <option value={3}>Instructor</option>
                                      <option value={2}>
                                        Gestor de cursos
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <button
                                    onClick={guardarUsuario}
                                    className="btn btn-success form-control"
                                  >
                                    Guardar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                          {/* form */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="p-4 border-bottom">
                <h4 className="title mb-0">Usuarios</h4>
              </div>
              <div className="p-4">
                <div
                  className="table-responsive bg-white "
                  //   shadow
                  style={{ justifyContent: "center", marginTop: "10px" }}
                >
                  <table
                    id="tabla_usuarios"
                    className="table table-center table-padding mb-0"
                  >
                    <thead>
                      <tr>
                        <th className="border-bottom py-3">#</th>
                        <th className="border-bottom text-center py-3">
                          Nombre
                        </th>
                        <th className="border-bottom text-center py-3">
                          Privilegios
                        </th>
                        <th className="border-bottom text-center py-3"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.length
                        ? users.map((user, index) => (
                            <tr key={index} className="shop-list">
                              <td className="text-center">{user.id_usuario}</td>
                              <td className="text-center">
                                {user.nombre_usuario}
                              </td>
                              <td className="text-center">{user.role}</td>
                              <td
                                style={{ justifyContent: "center" }}
                                className="text-center"
                              >
                                <button
                                  onClick={() => EditarUsuario(user)}
                                  className="btn btn-info mt-2 me-2"
                                >
                                  Editar
                                </button>
                                <button
                                  onClick={() =>
                                    EliminarUsuario(user.nombre_usuario)
                                  }
                                  className="btn btn-danger mt-2 me-2"
                                >
                                  Eliminar
                                </button>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default UserScreen;
