import React, { useState } from "react";
import { useEffect } from "react";
import "./acordeon.css";
import $ from "jquery";
import "datatables.net";
import "./../datatables.css";
import Swal from "sweetalert2";

function TeachersScreen() {
  const [isActive, setIsActive] = useState(false);
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    if (teachers.length > 0) {
      if (!$.fn.DataTable.isDataTable("#tabla_maestros")) {
        $("#tabla_maestros").DataTable({
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
          },
        });
      }
    }
  }, [teachers]);

  function EliminarMaestro(iid) {
    Swal.fire({
      title: `Eliminar instructor : <span>Osvaldo cruz</span>`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Continuar",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire("Se elimino de manera correcta!", "", "success");
      } else if (result.isDenied) {
        Swal.fire("Se cancelo la eliminación", "", "info");
      }
    });
  }

  const EditarMaestro = async () => {
    console.log("editar");
  };
  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="col-12 mt-4 pt-2">
            <div className="component-wrapper rounded shadow">
              <div style={{ justifyContent: "center" }}>
                <div className="accordion">
                  <div className="accordion-item">
                    <div
                      className="accordion-title"
                      onClick={() => setIsActive(!isActive)}
                    >
                      <div>Agregar Intructor</div>
                      <div>{isActive ? "-" : "+"}</div>
                    </div>
                    {isActive && (
                      <div className="accordion-content">
                        <div>
                          {/* form */}
                          <form>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Usuario
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <i
                                      data-feather="user"
                                      class="fea icon-sm icons"
                                    ></i>
                                    <select
                                      name="usuario"
                                      id="usuario"
                                      class="form-control ps-5"
                                    >
                                      <option>Selecione...</option>
                                      <option>usuario1</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Cv <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <i
                                      data-feather="user"
                                      class="fea icon-sm icons"
                                    ></i>
                                    <input
                                      name="cv"
                                      id="cv"
                                      type="file"
                                      class="form-control ps-5"
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Contraseña{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <i
                                      data-feather="user"
                                      class="fea icon-sm icons"
                                    ></i>
                                    <input
                                      name="password"
                                      id="password"
                                      type="password"
                                      class="form-control ps-5"
                                      placeholder="Contraseña :"
                                    />
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </form>
                          {/* form */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="p-4 border-bottom">
                <h4 className="title mb-0">Instructores</h4>
              </div>
              <div className="p-4">
                <div
                  className="table-responsive bg-white "
                  //   shadow
                  style={{ justifyContent: "center", marginTop: "10px" }}
                >
                  <table
                    id="tabla_maestros"
                    className="table table-center table-padding mb-0"
                  >
                    <thead>
                      <tr>
                        <th className="border-bottom py-3">#</th>
                        <th className="border-bottom text-center py-3">
                          Nombre
                        </th>
                        <th className="border-bottom text-center py-3">Tipo</th>
                        <th className="border-bottom text-center py-3">
                          Categorias
                        </th>

                        <th className="border-bottom text-center py-3"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="shop-list">
                        <td className="text-center">1</td>
                        <td className="text-center">Osvaldo cruz</td>

                        <td className="text-center">Instructor</td>
                        <td className="text-center"></td>

                        <td
                          style={{ justifyContent: "center" }}
                          className="text-center"
                        >
                          {/* <button
                            onClick={() => EditarMaestro()}
                            className="btn btn-info mt-2 me-2"
                          >
                            Editar
                          </button> */}
                          <button
                            onClick={() =>
                              EliminarMaestro({
                                nombre: "Osvaldo cruz",
                                id: "1",
                                tipo: "Instructor",
                              })
                            }
                            className="btn btn-danger mt-2 me-2"
                          >
                            Eliminar
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default TeachersScreen;
