import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL } from "../Global";
import "./modal.css";
import $ from "jquery";
import "datatables.net";
import "./../datatables.css";
import Swal from "sweetalert2";

function CategoryScreen() {
  const [isActive, setIsActive] = useState(false);
  const [categories, setCategories] = useState([]);
  const [nombre, setNombre] = useState("");
  useEffect(() => {
    const getCategories = async () => {
      const response = await axios.post(`${API_URL}groups/listaCategorias`);
      console.log(response.data);
      const newCategories = response.data.data.map((category) => {
        return {
          id_categoria: category.id_categoria,
          nombre_categoria: category.nombre_categoria,
        };
      });
      setCategories(newCategories);
    };
    const interval = setInterval(() => {
      getCategories();
    }, 5000);
    return () => clearInterval(interval);
  }, [categories]);

  function EliminarCategoria(cid) {
    Swal.fire({
      title: `Eliminar categoria : <span> ${cid.nombre_categoria}</span>`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Continuar",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire("Se elimino de manera correcta!", "", "success");
      } else if (result.isDenied) {
        Swal.fire("Se cancelo la eliminación", "", "info");
      }
    });
  }

  const EditarCategoria = async (cid) => {
    console.log("editar");
    const status = ["Activo", "Desactivo"];
    const selectStatus = status
      .map((stat) => `<option value="${stat}">${stat}</option>`)
      .join("");
    const { value: formValues } = await Swal.fire({
      title: "Editar categoria",
      html: `<form>
            <input id="nombre_categoria" class="swal2-input custom-select" placeholder="nombre_categoria" value="${cid.nombre_categoria}">
            
            <select id="role"  class="swal2-input custom-select">
              ${selectStatus}
            </select>
          </form>`,
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById("id_categoria").value,
          document.getElementById("nombre_categoria").value,
        ];
      },
    });
    if (formValues) {
      Swal.fire(JSON.stringify(formValues));
    }
  };
  useEffect(() => {
    if (categories.length > 0) {
      if (!$.fn.DataTable.isDataTable("#tabla_categorias")) {
        $("#tabla_categorias").DataTable({
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
          },
        });
      }
    }
  }, [categories]);

  const handlerNombreChange = (event) => {
    setNombre(event.target.value);
  };

  const guardarCategoria = async (event) => {
    event.preventDefault();
    console.log("guardar");
    if (nombre === "") {
      alert("llene todos los campos");
    } else {
      const response = await axios.post(`${API_URL}/class/altaCategoria`, {
        nombre: nombre,
      });
      console.log(response);
      if (response.status === 200) {
        console.log(response.data.message);
        alert(response.data.message);
        if (response.data.result_code === 400) {
          console.log("ERROR", response.data.error);
        }
        if (response.data.result_code === 200) {
        }
      }
    }
  };

  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="col-12 mt-4 pt-2">
            <div className="component-wrapper rounded shadow">
              <div style={{ justifyContent: "center" }}>
                <div className="accordion">
                  <div className="accordion-item">
                    <div
                      className="accordion-title"
                      onClick={() => setIsActive(!isActive)}
                    >
                      <div>Agregar Categoria</div>
                      <div>{isActive ? "-" : "+"}</div>
                    </div>
                    {isActive && (
                      <div className="accordion-content">
                        <div>
                          <form>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Nombre{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <i
                                      data-feather="user"
                                      class="fea icon-sm icons"
                                    ></i>
                                    <input
                                      name="nombre_categoria"
                                      id="nombre_categoria"
                                      type="text"
                                      value={nombre}
                                      onChange={handlerNombreChange}
                                      class="form-control ps-5"
                                      placeholder="Nombre :"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <button
                                    onClick={guardarCategoria}
                                    className="btn btn-success form-control"
                                  >
                                    Guardar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                          {/* form */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="p-4 border-bottom">
                <h4 className="title mb-0">Categorias</h4>
              </div>
              <div className="p-4">
                <div
                  className="table-responsive bg-white "
                  //   shadow
                  style={{ justifyContent: "center", marginTop: "10px" }}
                >
                  <table
                    id="tabla_categorias"
                    className="table table-center table-padding mb-0"
                  >
                    <thead>
                      <tr>
                        <th className="border-bottom py-3">#</th>
                        <th className="border-bottom text-center py-3">
                          Nombre
                        </th>

                        <th className="border-bottom text-center py-3"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {categories.length
                        ? categories.map((category, index) => (
                            <tr key={index} className="shop-list">
                              <td className="text-center">
                                {category.id_categoria}
                              </td>
                              <td className="text-center">
                                {category.nombre_categoria}
                              </td>
                              <td
                                style={{ justifyContent: "center" }}
                                className="text-center"
                              >
                                <button
                                  onClick={() => EditarCategoria(category)}
                                  className="btn btn-info mt-2 me-2"
                                >
                                  Editar
                                </button>
                                <button
                                  onClick={() => EliminarCategoria(category)}
                                  className="btn btn-danger mt-2 me-2"
                                >
                                  Eliminar
                                </button>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default CategoryScreen;
