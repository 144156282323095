import React from "react";
import Background from "./components/Background";
import Footer from "./components/Footer";
import InfoLanding from "./components/InfoLanding";
import NavBar from "./components/NavBar";
import Questions from "./components/Questions";
import SectionsCurses from "./components/SectionsCurses";
import SectionsTutors from "./components/SectionsTutors";

function LandingScreen() {
  return (
    <div>
      <NavBar />
      <Background />
      <SectionsTutors />
      <InfoLanding />
      <SectionsCurses />
      <Questions />
      <Footer />
    </div>
  );
}
export default LandingScreen;
