import React from "react";
import Contact from "./Contact";

function InfoLanding() {
  return (
    <section className="section bg-light border-bottom">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">Como funciona ?</h4>
              <p className="text-muted para-desc mb-0 mx-auto">
                Start working with
                <span className="text-primary fw-bold">Bluehand</span> Quis
                nostrud officia cillum nisi est nulla commodo quis nisi.
              </p>
            </div>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-lg-5 col-md-6 mt-4 pt-2">
            <img src="assets/images/illustrator/SEO_SVG.svg" alt="" />
          </div>

          <div className="col-lg-7 col-md-6 mt-4 pt-2">
            <div className="section-title ms-lg-5">
              <h4 className="title mb-4">Cambia a bluehand</h4>
              <p className="text-muted">
                Cillum aute reprehenderit in sit adipisicing cupidatat
                reprehenderit proident non eiusmod esse ullamco qui sunt. Cillum
                esse ea laborum nostrud fugiat velit id ullamco. Ullamco
                deserunt Lorem aliqua veniam quis irure irure aliqua velit
                adipisicing duis deserunt do. Proident cillum in sit minim
                labore quis culpa occaecat esse ea fugiat. Eu magna amet minim
                est cillum dolor ea consectetur culpa commodo nulla. Excepteur
                proident amet sunt nisi. Magna exercitation duis pariatur
                proident sunt Lorem.
              </p>
              <ul className="list-unstyled text-muted">
                <li className="mb-0">
                  <span className="text-primary h5 me-2">
                    <i className="uil uil-check-circle align-middle"></i>
                  </span>
                  Consequat ipsum excepteur culpa et elit exercitation velit est
                  deserunt ullamco consequat sint nulla.
                </li>
                <li className="mb-0">
                  <span className="text-primary h5 me-2">
                    <i className="uil uil-check-circle align-middle"></i>
                  </span>
                  Consequat ipsum excepteur culpa et elit exercitation velit est
                  deserunt ullamco consequat sint nulla.
                </li>
                <li className="mb-0">
                  <span className="text-primary h5 me-2">
                    <i className="uil uil-check-circle align-middle"></i>
                  </span>{" "}
                  Consequat ipsum excepteur culpa et elit exercitation velit est
                  deserunt ullamco consequat sint nulla.
                </li>
              </ul>
              <a href="#" className="mt-3 h6 text-primary">
                leer mas <i className="uil uil-angle-right-b align-middle"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <Contact />
    </section>
  );
}
export default InfoLanding;
