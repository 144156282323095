import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

function CalendarScreen() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleDateClick = (arg) => {
    setSelectedDate(arg.date);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="col-12 mt-4 pt-2">
            <div className="component-wrapper rounded shadow">
              <div className="p-4 border-bottom">
                <h4 className="title mb-0">Calendario</h4>
              </div>
              <div>
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  initialView="dayGridMonth"
                  events={[
                    { title: "Clase 1", date: "2023-03-01" },
                    { title: "Clase 2", date: "2023-03-02" },
                  ]}
                  contentHeight="600px"
                  dateClick={handleDateClick}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {selectedDate && showModal && (
        <div className="modal" onClick={handleModalClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>Fecha seleccionada: {selectedDate.toISOString()}</h2>
            <button onClick={handleModalClose}>Cerrar</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CalendarScreen;
