import { Route, Routes } from "react-router-dom";
import "./App.css";
import CalendarScreen from "./private/CalendarScreen";
import CategoryScreen from "./private/CategorySceen";
import CertificateScreen from "./private/CertificateScreen";
import CursesScreen from "./private/CursesScreen";
import HomeScreen from "./private/HomeScreen";
import RequestScreen from "./private/RequestScreen";
import TeachersScreen from "./private/TeachersScreen";
import UserScreen from "./private/UserScreen";
import Auth from "./public/Auth";
import LandingScreen from "./public/LandingScreen";

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingScreen />} />
      <Route path="Ingresar" element={<Auth />} />
      <Route path="Inicio" element={<HomeScreen />}>
        <Route path="usuarios" element={<UserScreen />} />
        <Route path="cursos" element={<CursesScreen />} />
        <Route path="categorias" element={<CategoryScreen />} />
        <Route path="certificados" element={<CertificateScreen />} />
        <Route path="maestros" element={<TeachersScreen />} />
        <Route path="solicitudes" element={<RequestScreen />} />
        <Route path="calendario" element={<CalendarScreen />} />
      </Route>
    </Routes>
  );
}

export default App;
