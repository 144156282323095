import React from "react";

function Contact() {
  return (
    <div className="container mt-100 mt-60">
      <div className="row align-items-center">
        <div className="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div className="section-title">
            <h4 className="title mb-4">
              Contrata <br />
              with <span className="text-primary">Bluehand.</span>
            </h4>
            <p className="text-muted">
              Excepteur enim laboris anim occaecat qui amet quis irure sint
              officia. Aliqua ipsum enim magna deserunt eiusmod. Deserunt est
              ipsum cillum pariatur aute ullamco nostrud commodo pariatur ipsum
              consequat elit ad in. Proident dolore ut consectetur sint laborum
              id. Sunt Lorem pariatur fugiat tempor nostrud. Veniam excepteur ex
              esse laboris do proident veniam ad ex duis.
            </p>
            <ul className="list-unstyled text-muted">
              <li className="mb-0">
                <span className="text-primary h5 me-2">
                  <i className="uil uil-check-circle align-middle"></i>
                </span>
                Cursos a la medida
              </li>
              <li className="mb-0">
                <span className="text-primary h5 me-2">
                  <i className="uil uil-check-circle align-middle"></i>
                </span>
                Planes a la medidad
              </li>
              <li className="mb-0">
                <span className="text-primary h5 me-2">
                  <i className="uil uil-check-circle align-middle"></i>
                </span>
                Commodo nostrud enim deserunt fugiat in velit non fugiat.
              </li>
            </ul>
            <a href="#" className="mt-3 h6 text-primary">
              leer mas <i className="uil uil-angle-right-b align-middle"></i>
            </a>
          </div>
        </div>

        <div className="col-lg-5 col-md-6 order-1 order-md-2">
          <div className="card rounded border-0 shadow ms-lg-5">
            <div className="card-body">
              <img
                src="assets/images/illustrator/Mobile_notification_SVG.svg"
                alt=""
              />

              <div className="content mt-4 pt-2">
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Nombre/empresa :{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="form-icon position-relative">
                          <i
                            data-feather="user"
                            className="fea icon-sm icons"
                          ></i>
                          <input
                            type="text"
                            className="form-control ps-5"
                            placeholder="Nombre/empresa"
                            name="name"
                            required=""
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Correo : <span className="text-danger">*</span>
                        </label>
                        <div className="form-icon position-relative">
                          <i
                            data-feather="mail"
                            className="fea icon-sm icons"
                          ></i>
                          <input
                            type="email"
                            className="form-control ps-5"
                            placeholder="Correo electronico"
                            name="email"
                            required=""
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Mensaje :<span className="text-danger">*</span>
                        </label>
                        <div className="form-icon position-relative">
                          <i
                            data-feather="key"
                            className="fea icon-sm icons"
                          ></i>
                          <textarea
                            type="text"
                            className="form-control ps-5"
                            placeholder="escribe tu solicitud"
                            required=""
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 mt-2 mb-0">
                      <div className="d-grid">
                        <button className="btn btn-primary">Enviar</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;
