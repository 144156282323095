import React from "react";
import "./cardtutors.scss";

function CardTutors() {
  return (
    <div
      style={{ margin: "5px" }}
      className="col-xl-3 col-lg-4 col-md-6 col-sm-12  text-center"
    >
      <div
        className="card-t"
        style={{
          backgroundImage: `url("assets/images/persons/tutor.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="content-t">
          <h2 className="title-t">Instructor</h2>
          <h6>Raul romo saucedo</h6>
          <p className="copy-t">
            Minim Lorem est et Lorem irure voluptate magna commodo nostrud
            dolore.
          </p>
          <button className="btn-t"> Ver mas...</button>
        </div>
      </div>
    </div>
  );
}
export default CardTutors;
