import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "./components/NavBar";
import "./auth.css";
import Footer from "./components/Footer";

function Auth() {
  const [isPanelActive, setIsPanelActive] = useState(false);
  let navigate = useNavigate();
  const container = document.getElementById("container");

  function signUpclick() {
    console.log("signUpclick");
    setIsPanelActive(false);
  }
  function signInclick() {
    console.log("signInclick");
  }
  function checkLogin() {
    console.log("checkLogin");
    navigate("/Inicio");
  }
  return (
    <div>
      <NavBar />
      <div
        style={{
          justifyContent: "center",
          marginTop: "200px",
          paddingLeft: "550px",
          marginBottom: "200px",
        }}
        className="body-login container-body-auth"
      >
        <div
          className={`container-login  ${
            isPanelActive ? "right-panel-active" : ""
          } `}
          id="container"
        >
          <div className="form-container sign-up-container">
            <form className="form-login" action="#">
              <h1 className="h1-login">Crear una cuenta</h1>
              <div className="social-container">
                <a href="#" className="social a-login">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#" className="social">
                  <i className="fab fa-google-plus-g"></i>
                </a>
                <a href="#" className="social">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
              <span className="span-login">
                O use su correo electrónico para registrarse
              </span>
              <input
                className="input-login"
                type="text"
                placeholder="Nombre "
              />
              <input
                className="input-login"
                type="email"
                placeholder="Correo Electronico "
              />
              <input
                className="input-login"
                type="password"
                placeholder="Contraseña"
              />
              <button className="button-login">Registrarse</button>
            </form>
          </div>
          <div className="form-container sign-in-container">
            <form className="form-login">
              <h1 className="h1-login">Iniciar sesión</h1>
              {/* <div className="social-container">
                <a href="#" className="social">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#" className="social a-login">
                  <i className="fab fa-google-plus-g"></i>
                </a>
                <a href="#" className="social a-login">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div> */}
              <span className="span-login">Usa tu cuenta</span>
              <input
                className="input-login"
                type="email"
                placeholder="Correo Electronico"
              />
              <input
                className="input-login"
                type="password"
                placeholder="Contraseña"
              />
              <button onClick={checkLogin} className="button-login">
                Iniciar sesión
              </button>
              <div>
                <a href="#">¿Olvidó su contraseña?</a>
              </div>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1>Bienvenido de nuevo!</h1>
                <p className="p-login">
                  Para mantenerse conectado con nosotros, inicie sesión con su
                  información personal
                </p>
                <button
                  className="ghost button-login"
                  onClick={signInclick}
                  id="signIn"
                >
                  Iniciar sesión
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <h1>Hola,Estudiante!</h1>
                <p className="p-login">
                  Ingresa tus datos personales y comienza tus cursos con
                  nosotros
                </p>
                <button
                  className="ghost button-login"
                  onClick={signUpclick}
                  id="signUp"
                >
                  Registrarse
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Auth;
