import React, { useState } from "react";
import Swal from "sweetalert2";

function CertificateScreen() {
  const [isActive, setIsActive] = useState(false);

  function EliminarCertificado() {
    console.log(EliminarCertificado, " ");
    // nombre_usuario
    Swal.fire({
      title: `Eliminar usuario : <span> NOM035 </span>`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Continuar",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire("Se elimino de manera correcta!", "", "success");
      } else if (result.isDenied) {
        Swal.fire("Se cancelo la eliminación", "", "info");
      }
    });
  }

  const EditarCertificado = async () => {
    console.log("editar");
    const status = ["Activo", "Desactivo"];
    const selectStatus = status
      .map((stat) => `<option value="${stat}">${stat}</option>`)
      .join("");
    const { value: formValues } = await Swal.fire({
      title: "Editar certificado",
      html: `<form>
            <input id="nombre_certificado" class="swal2-input custom-select"  value="NOM035">
            <input id="archivo_certificado" type="file" class="swal2-input custom-select">
            <select id="status"  class="swal2-input custom-select" value="Activo">
              ${selectStatus}
            </select>
          </form>`,
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById("nombre_certificado").value,
          document.getElementById("status").value,
          document.getElementById("archivo_certificado").files[0],
        ];
      },
    });
    if (formValues) {
      Swal.fire(JSON.stringify(formValues));
    }
  };
  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="col-12 mt-4 pt-2">
            <div className="component-wrapper rounded shadow">
              <div style={{ justifyContent: "center" }}>
                <div className="accordion">
                  <div className="accordion-item">
                    <div
                      className="accordion-title"
                      onClick={() => setIsActive(!isActive)}
                    >
                      <div>Agregar certificado</div>
                      <div>{isActive ? "-" : "+"}</div>
                    </div>
                    {isActive && (
                      <div className="accordion-content">
                        <div>
                          {/* form */}
                          <form>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Nombre{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <i
                                      data-feather="user"
                                      class="fea icon-sm icons"
                                    ></i>
                                    <input
                                      name="name"
                                      id="name"
                                      type="text"
                                      class="form-control ps-5"
                                      placeholder="Nombre :"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Certiicado
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <i
                                      data-feather="user"
                                      class="fea icon-sm icons"
                                    ></i>
                                    <input
                                      name="certificado"
                                      id="certificado"
                                      type="file"
                                      class="form-control ps-5"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                          {/* form */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="p-4 border-bottom">
                <h4 className="title mb-0">Centificados</h4>
              </div>
              <div className="p-4">
                <div
                  className="table-responsive bg-white "
                  //   shadow
                  style={{ justifyContent: "center", marginTop: "10px" }}
                >
                  <table className="table table-center table-padding mb-0">
                    <thead>
                      <tr>
                        <th className="border-bottom py-3">#</th>
                        <th className="border-bottom text-center py-3">
                          Nombre
                        </th>
                        <th className="border-bottom text-center py-3">
                          documento
                        </th>

                        <th className="border-bottom text-center py-3"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="shop-list">
                        <td className="text-center">1</td>
                        <td className="text-center">NOM035</td>

                        <td className="text-center">cetificado.cv</td>
                        <td
                          style={{ justifyContent: "center" }}
                          className="text-center"
                        >
                          <button
                            onClick={() => EditarCertificado()}
                            className="btn btn-info mt-2 me-2"
                          >
                            Editar
                          </button>
                          <button
                            onClick={() => EliminarCertificado()}
                            className="btn btn-danger mt-2 me-2"
                          >
                            Eliminar
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default CertificateScreen;
