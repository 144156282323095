import React from "react";

function CardCurses(props) {
  let user = props.usuario;
  return (
    <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
      <div className="card shop-list border-0 position-relative">
        <ul className="label list-unstyled mb-0">
          <li>
            <a className="badge badge-link rounded-pill bg-success">
              {user.categoria}
            </a>
          </li>
        </ul>
        <div className="shop-image position-relative overflow-hidden rounded shadow">
          <a href="shop-product-detail.html">
            <img
              src={`assets/images/curses/${user.imagen}`}
              className="img-fluid"
              alt=""
            />
          </a>
          <a href="shop-product-detail.html" className="overlay-work">
            <img
              src={`assets/images/curses/${user.imagen}`}
              className="img-fluid"
              alt=""
            />
          </a>
          <ul className="list-unstyled shop-icons">
            <li>
              <a className="btn btn-icon btn-pills btn-soft-danger">
                <i data-feather="heart" className="icons"></i>
              </a>
            </li>
            {/* <li className="mt-2">
              <a
                data-bs-toggle="modal"
                data-bs-target="#productview"
                className="btn btn-icon btn-pills btn-soft-primary"
              >
                <i data-feather="eye" className="icons"></i>
              </a>
            </li> */}
            <li className="mt-2">
              <a
                href="shop-cart.html"
                className="btn btn-icon btn-pills btn-soft-primary"
              >
                <i data-feather="shopping-cart" className="icons"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="card-body content pt-4 p-2">
          <div className="row">
            <div className="col">
              <a
                href="shop-product-detail.html"
                className="text-dark product-name h6"
              >
                {user.titulo}
              </a>
              <div className="d-flex justify-content-between mt-1">
                <h6 className="text-muted small fst-italic mb-0 mt-1">
                  ${user.precio}
                  {/* <del className="text-danger ms-2">$21.00</del>{" "} */}
                </h6>
              </div>
            </div>
            <div className="col">
              <div>
                <button className="btn btn-soft-primary">Comprar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CardCurses;
