import React, { useState } from "react";
import "./acordeon.css";

function RequestScreen() {
  const [isActive, setIsActive] = useState(false);

  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="col-12 mt-4 pt-2">
            <div className="component-wrapper rounded shadow">
              <div style={{ justifyContent: "center" }}>
                <div className="accordion">
                  <div className="accordion-item">
                    <div
                      className="accordion-title"
                      onClick={() => setIsActive(!isActive)}
                    >
                      <div>Solicitudes</div>
                      <div>{isActive ? "-" : "+"}</div>
                    </div>
                    {isActive && (
                      <div className="accordion-content">
                        <div>
                          {/* form */}
                          {/* <form>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Nombre{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <i
                                      data-feather="user"
                                      class="fea icon-sm icons"
                                    ></i>
                                    <input
                                      name="name"
                                      id="name"
                                      type="text"
                                      class="form-control ps-5"
                                      placeholder="Nombre :"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Correo{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <i
                                      data-feather="user"
                                      class="fea icon-sm icons"
                                    ></i>
                                    <input
                                      name="correo"
                                      id="correo"
                                      type="text"
                                      class="form-control ps-5"
                                      placeholder="Correo :"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Contraseña{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <i
                                      data-feather="user"
                                      class="fea icon-sm icons"
                                    ></i>
                                    <input
                                      name="password"
                                      id="password"
                                      type="password"
                                      class="form-control ps-5"
                                      placeholder="Contraseña :"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Tipo de usuario
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <i
                                      data-feather="user"
                                      class="fea icon-sm icons"
                                    ></i>
                                    <select
                                      name="tipo"
                                      id="tipo"
                                      class="form-control ps-5"
                                    >
                                      <option>Selecione...</option>
                                      <option>Alumno</option>
                                      <option>Administrador</option>
                                      <option>Gestor de cursos</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form> */}
                          {/* form */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="p-4 border-bottom">
                <h4 className="title mb-0">Solicitudes</h4>
              </div>
              <div className="p-4">
                <div
                  className="table-responsive bg-white "
                  //   shadow
                  style={{ justifyContent: "center", marginTop: "10px" }}
                >
                  <table className="table table-center table-padding mb-0">
                    <thead>
                      <tr>
                        <th className="border-bottom py-3">#</th>
                        <th className="border-bottom text-center py-3">
                          Nombre
                        </th>
                        <th className="border-bottom text-center py-3">
                          Privilegios
                        </th>

                        <th className="border-bottom text-center py-3"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="shop-list">
                        <td className="text-center">1</td>
                        <td className="text-center">Osvaldo cruz</td>

                        <td className="text-center">Administrador</td>
                        <td
                          style={{ justifyContent: "center" }}
                          className="text-center"
                        >
                          <button className="btn btn-info mt-2 me-2">
                            Editar
                          </button>
                          <button className="btn btn-danger mt-2 me-2">
                            Eliminar
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default RequestScreen;
