import React from "react";
import CardCurses from "./CardCurses";

function SectionsCurses() {
  const datos = [
    {
      titulo: "ISO",
      precio: 1000,
      imagen: "ISO_LOGO.svg",
      categoria: "seguridad",
    },
    {
      titulo: "ISO",
      precio: 2000,
      imagen: "iso-2.png",
      categoria: "administracion",
    },
    {
      titulo: "NOM",
      precio: 1500,
      imagen: "nom.jpg",
      categoria: "gestión de riesgos",
    },
  ];
  console.log(datos);

  return (
    <section className="section pb-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <h6>Cursos</h6>
              <h4 className="title mb-4">Elige tus clases</h4>
              {/* <p className="text-muted para-desc mx-auto mb-0">
                Start working with
                <span className="text-primary fw-bold"> Bluehand </span> Elit
                excepteur non proident velit laborum et duis fugiat amet.
                Incididunt adipisicing laboris nisi ad eu. Cupidatat laboris
                reprehenderit veniam est duis quis.
              </p> */}
            </div>
          </div>
        </div>

        <div style={{ justifyContent: "center" }} className="row">
          {datos.map((usuario, index) => (
            <CardCurses usuario={usuario} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default SectionsCurses;
