import React from "react";
import CardTutors from "./CardTutors";

function SectionsTutors() {
  return (
    <section className="section pb-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <h6>Conoce a nuestros instructores</h6>
              <h4 className="title mb-4">Trabaja con los mejores maestros</h4>
              <p className="text-muted para-desc mx-auto mb-0">
                Start working with
                <span className="text-primary fw-bold"> Bluehand </span> Elit
                excepteur non proident velit laborum et duis fugiat amet.
                Incididunt adipisicing laboris nisi ad eu. Cupidatat laboris
                reprehenderit veniam est duis quis.
              </p>
            </div>
          </div>
        </div>

        <div style={{ justifyContent: "center" }} className="row">
          <CardTutors />
          <CardTutors />
          <CardTutors />
          <CardTutors />
          <CardTutors />
          <CardTutors />
        </div>
      </div>
    </section>
  );
}
export default SectionsTutors;
