import React, { useEffect, useState } from "react";
import { API_URL } from "../Global";
import $ from "jquery";
import "datatables.net";
import "./../datatables.css";
import axios from "axios";
import Swal from "sweetalert2";

function CursesScreen() {
  const [isActive, setIsActive] = useState(false);
  const [curses, setCurses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [nombre, setNombre] = useState("");
  const [desc, setDesc] = useState("");
  const [categoria, setCategoria] = useState("");
  useEffect(() => {
    const getCurses = async () => {
      const response = await axios.post(`${API_URL}class/listaCurso`);
      console.log("response  : ", response);
      if (response.status == 200) {
        if (response.data.result_code == 200) {
          const newCurses = response.data.data.map((curse) => {
            return {
              id_curso: curse.id_curso,
              nombre_curso: curse.nombre_curso,
              desc_curso: curse.desc_curso,
              fecha_alta_curso: curse.fecha_alta_curso,
              imagen_curso: curse.imagen_curso,
              nombre_categoria: curse.nombre_categoria,
              id_categoria: curse.id_categoria,
            };
          });
          setCurses(newCurses);
        }
      }
    };
    const interval = setInterval(() => {
      getCurses();
    }, 5000);
    return () => clearInterval(interval);
  }, [curses]);

  useEffect(() => {
    const getCategories = async () => {
      const response = await axios.post(`${API_URL}groups/listaCategorias`);
      console.log("lista cat : ", response);
      if (response.status == 200) {
        if (response.data.result_code) {
          const newCategories = response.data.data.map((category) => {
            return {
              id_categoria: category.id_categoria,
              nombre_categoria: category.nombre_categoria,
            };
          });
          setCategories(newCategories);
        }
      }
    };
    const interval = setInterval(() => {
      getCategories();
    }, 5000);
    return () => clearInterval(interval);
  }, [categories]);

  useEffect(() => {
    if (curses.length > 0) {
      if (!$.fn.DataTable.isDataTable("#tabla_cursos")) {
        $("#tabla_cursos").DataTable({
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
          },
        });
      }
    }
  }, [curses]);

  function EliminarCurso() {
    console.log(EliminarCurso, " ");
    // nombre_usuario
    Swal.fire({
      title: `Eliminar Curso : <span> NOM035</span>`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Continuar",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire("Se elimino de manera correcta!", "", "success");
      } else if (result.isDenied) {
        Swal.fire("Se cancelo la eliminación", "", "info");
      }
    });
  }

  const EditarCurso = async () => {
    const status = ["Activo", "Desactivo"];
    const Categorias = [
      "Seguridad",
      "Auditoria",
      "Gestión de riesgos",
      "Implementación",
    ];
    const selectOptions = Categorias.map(
      (option) => `<option value="${option}">${option}</option>`
    ).join("");
    const selectStatus = status
      .map((stat) => `<option value="${stat}">${stat}</option>`)
      .join("");
    const { value: formValues } = await Swal.fire({
      title: "Editar Curso",
      html: `<form>
            <input id="nombre_curso" class="swal2-input custom-select" placeholder="Nombre Usuario" value="NOM035">
            <select id="categoria"  class="swal2-input custom-select" value="Seguridad">
              ${selectOptions}
            </select>
            <select id="status"  class="swal2-input custom-select" value="Activo">
              ${selectStatus}
            </select>
          </form>`,
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById("id_usuario").value,
          document.getElementById("categoria").value,
          document.getElementById("status").value,
        ];
      },
    });
    if (formValues) {
      Swal.fire(JSON.stringify(formValues));
    }
  };
  const handlerNombreChange = (event) => {
    setNombre(event.target.value);
  };
  const handlerDescChange = (event) => {
    setDesc(event.target.value);
  };
  const handlerCategoriaChange = (event) => {
    setCategoria(event.target.value);
  };
  const guardarCurso = async (event) => {
    event.preventDefault();
    console.log("guardar ");
    console.log("Nombre ", nombre);
    console.log("cat ", categoria);
    console.log("desc ", desc);
    if (nombre == "" || categoria == 0 || desc == "") {
      console.log("faltan datos");
    } else {
      const response = await axios.post(`${API_URL}class/alta`, {
        nombre: nombre,
        id_categoria: categoria,
        descripcion: desc,
      });
      console.log("curso alta", response);
    }
  };
  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="col-12 mt-4 pt-2">
            <div className="component-wrapper rounded shadow">
              <div style={{ justifyContent: "center" }}>
                <div className="accordion">
                  <div className="accordion-item">
                    <div
                      className="accordion-title"
                      onClick={() => setIsActive(!isActive)}
                    >
                      <div>Agregar Curso</div>
                      <div>{isActive ? "-" : "+"}</div>
                    </div>
                    {isActive && (
                      <div className="accordion-content">
                        <div>
                          {/* form */}
                          <form>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Nombre{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <i
                                      data-feather="user"
                                      className="fea icon-sm icons"
                                    ></i>
                                    <input
                                      name="name"
                                      id="name"
                                      value={nombre}
                                      onChange={handlerNombreChange}
                                      type="text"
                                      className="form-control ps-5"
                                      placeholder="Nombre :"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Categoria
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <i
                                      data-feather="user"
                                      className="fea icon-sm icons"
                                    ></i>
                                    <select
                                      name="tipo"
                                      id="tipo"
                                      onChange={handlerCategoriaChange}
                                      className="form-control ps-5"
                                    >
                                      {categories.length ? (
                                        <option value={0}>Selecione...</option>
                                      ) : (
                                        <option value={null}>
                                          Cargando...
                                        </option>
                                      )}
                                      {categories.map((category, index) => (
                                        <option
                                          key={index}
                                          value={category.id_categoria}
                                        >
                                          {category.nombre_categoria}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Nombre{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <i
                                      data-feather="user"
                                      className="fea icon-sm icons"
                                    ></i>
                                    <textarea
                                      name="name"
                                      id="name"
                                      value={desc}
                                      onChange={handlerDescChange}
                                      type="text"
                                      className="form-control ps-5"
                                      placeholder="Descripción :"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <button
                                    onClick={guardarCurso}
                                    className="btn btn-success form-control"
                                  >
                                    Guardar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                          {/* form */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="p-4 border-bottom">
                <h4 className="title mb-0">Cursos</h4>
              </div>
              <div className="p-4">
                <div
                  className="table-responsive bg-white "
                  //   shadow
                  style={{ justifyContent: "center", marginTop: "10px" }}
                >
                  <table
                    id="tabla_cursos"
                    className="table table-center table-padding mb-0"
                  >
                    <thead>
                      <tr>
                        <th className="border-bottom py-3">#</th>
                        <th className="border-bottom text-center py-3">
                          Nombre
                        </th>
                        <th className="border-bottom text-center py-3">
                          Cateogoria
                        </th>

                        <th className="border-bottom text-center py-3"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {curses.length
                        ? curses.map((curse, index) => (
                            <tr key={index} className="shop-list">
                              <td className="text-center">{curse.id_curso}</td>
                              <td className="text-center">
                                {curse.nombre_curso}
                              </td>
                              <td className="text-center">
                                {curse.nombre_categoria}
                              </td>
                              <td
                                style={{ justifyContent: "center" }}
                                className="text-center"
                              >
                                <button
                                  onClick={() => EditarCurso(curse)}
                                  className="btn btn-info mt-2 me-2"
                                >
                                  Editar
                                </button>
                                <button
                                  onClick={() =>
                                    EliminarCurso(curse.nombre_usuario)
                                  }
                                  className="btn btn-danger mt-2 me-2"
                                >
                                  Eliminar
                                </button>
                              </td>
                            </tr>
                          ))
                        : null}
                      {/* <tr className="shop-list">
                        <td className="text-center">1</td>
                        <td className="text-center">NOM035</td>

                        <td className="text-center">Seguridad</td>
                        <td
                          style={{ justifyContent: "center" }}
                          className="text-center"
                        >
                          <button
                            onClick={() => EditarCurso()}
                            className="btn btn-info mt-2 me-2"
                          >
                            Editar
                          </button>
                          <button
                            onClick={() => EliminarCurso()}
                            className="btn btn-danger mt-2 me-2"
                          >
                            Eliminar
                          </button>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default CursesScreen;
