import React from "react";

function Footer() {
  return (
    <>
      <div className="position-relative">
        <div className="shape overflow-hidden text-footer">
          <svg
            viewBox="0 0 2880 250"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M720 125L2160 0H2880V250H0V125H720Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
              <a href="#" className="logo-footer">
                <img src="assets/images/logo-light.png" height="24" alt="" />
              </a>
              <p className="mt-4">
                Incididunt eu quis sit fugiat non cupidatat cupidatat.
              </p>
              <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                <li className="list-inline-item">
                  <a href="#" className="rounded">
                    <i
                      data-feather="facebook"
                      className="fea icon-sm fea-social"
                    ></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="rounded">
                    <i
                      data-feather="instagram"
                      className="fea icon-sm fea-social"
                    ></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="rounded">
                    <i
                      data-feather="twitter"
                      className="fea icon-sm fea-social"
                    ></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="rounded">
                    <i
                      data-feather="linkedin"
                      className="fea icon-sm fea-social"
                    ></i>
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h5 className="text-light footer-head">Bluehand</h5>
              <ul className="list-unstyled footer-list mt-4">
                <li>
                  <a href="page-aboutus.html" className="text-foot">
                    <i className="uil uil-angle-right-b me-1"></i> Nosostros
                  </a>
                </li>
                <li>
                  <a href="page-services.html" className="text-foot">
                    <i className="uil uil-angle-right-b me-1"></i> Servicios
                  </a>
                </li>
                <li>
                  <a href="page-team.html" className="text-foot">
                    <i className="uil uil-angle-right-b me-1"></i> Equipo
                  </a>
                </li>

                <li>
                  <a href="auth-cover-login.html" className="text-foot">
                    <i className="uil uil-angle-right-b me-1"></i> Inicio
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h5 className="text-light footer-head">Enlaces</h5>
              <ul className="list-unstyled footer-list mt-4">
                <li>
                  <a href="page-terms.html" className="text-foot">
                    <i className="uil uil-angle-right-b me-1"></i> Terminos de
                    servicio
                  </a>
                </li>
                <li>
                  <a href="page-privacy.html" className="text-foot">
                    <i className="uil uil-angle-right-b me-1"></i> Politicas de
                    privacidad
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h5 className="text-light footer-head">Información</h5>
              <p className="mt-4">
                Reprehenderit labore magna anim irure sunt commodo fugiat ut
                exercitation sint fugiat.
              </p>
            </div>
          </div>
        </div>
      </footer>
      <footer className="footer footer-bar">
        <div className="container text-center">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="text-sm-start">
                <p className="mb-0">
                  © <script>document.write(new Date().getFullYear())</script>{" "}
                  <i className="mdi mdi-heart text-danger"></i> Derechos
                  reservados{" "}
                  <a
                    href="https://shreethemes.in/"
                    target="_blank"
                    className="text-reset"
                  >
                    BlueHand Consulting Group
                  </a>
                  .
                </p>
              </div>
            </div>

            <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <ul className="list-unstyled text-sm-end mb-0">
                <li className="list-inline-item">
                  <a href="#">
                    <img
                      src="assets/images/payments/american-ex.png"
                      className="avatar avatar-ex-sm"
                      title="American Express"
                      alt=""
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <img
                      src="assets/images/payments/discover.png"
                      className="avatar avatar-ex-sm"
                      title="Discover"
                      alt=""
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <img
                      src="assets/images/payments/master-card.png"
                      className="avatar avatar-ex-sm"
                      title="Master Card"
                      alt=""
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <img
                      src="assets/images/payments/paypal.png"
                      className="avatar avatar-ex-sm"
                      title="Paypal"
                      alt=""
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <img
                      src="assets/images/payments/visa.png"
                      className="avatar avatar-ex-sm"
                      title="Visa"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
