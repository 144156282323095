import React from "react";

function Background() {
  return (
    <section className="bg-half-170 d-table w-100">
      <div className="container">
        <div className="row mt-5 align-items-center">
          <div className="col-lg-7 col-md-7">
            <div className="title-heading me-lg-4">
              <h1 className="heading mb-3">
                Universidad <span className="text-primary">bluehand</span>{" "}
              </h1>
              <p className="para-desc text-muted">
                Proident Lorem dolore sit qui laborum aliqua aute elit nulla
                Lorem non mollit. Sit amet eu duis fugiat voluptate
                reprehenderit ea ipsum ea. Proident consequat ex officia quis
                aliquip quis officia eiusmod labore deserunt quis.
              </p>
              <div className="mt-4">
                <a
                  href="page-contact-one.html"
                  className="btn btn-primary mt-2 me-2"
                >
                  <i className="uil uil-envelope"></i> Solicita un cursos para
                  tu empresa
                </a>
                <a
                  href="documentation.html"
                  className="btn btn-outline-primary mt-2"
                >
                  <i className="uil uil-book-alt"></i> Ver cursos
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <img
              style={{ width: "100%" }}
              src="assets/images/illustrator/Startup_SVG.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}
export default Background;
